import React, { useState, useEffect } from "react";
import { Form, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

export function SignupAvailabilityForm() {
  // const [optionFrequencyPreferences, setOptionFrequencyPreferences] = useState(
  //   []
  // );
  // const [headerCheck, setHeaderCheck] = useState({
  //   monday: false,
  //   tuesday: false,
  //   wednesday: false,
  //   thursday: false,
  //   friday: false,
  //   saturday: false,
  //   sunday: false,
  // });

  return (
    <>
      <Form.Group as={Row} className={"mb-5"}>
        <Form.Group className={"col-lg-4"}>
          <Form.Label>Frequency</Form.Label>

          <Select
            className="basic-single"
            classNamePrefix="select"
            // isLoading={contactReducer.frequency === null}
            onChange={(event) => event.value}
            // value={
            //   optionFrequencyPreferences.filter(
            //     (ar) => ar.value === watch("frequency")
            //   )[0]
            // }
            // isSearchable={true}
            // options={optionFrequencyPreferences}
            // styles={errors.frequency && styleSelect}
          />

          {/* <Form.Label className={"text-danger"}>
            {errors?.frequency?.message}
          </Form.Label> */}
        </Form.Group>
      </Form.Group>
      <Table bordered hover>
        <thead>
          <tr className="text-center">
            <th>Availability</th>
            <th
            // onClick={() => handleCheckHeader("monday", !headerCheck.monday)}
            >
              <Form.Check
                label="Monday"
                // checked={
                //   watch("monday[morning]") &&
                //   watch("monday[afternoon]") &&
                //   watch("monday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("monday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() =>
            //   handleCheckHeader("tuesday", !headerCheck.tuesday)
            // }
            >
              <Form.Check
                label="Tuesday"
                // checked={
                //   watch("tuesday[morning]") &&
                //   watch("tuesday[afternoon]") &&
                //   watch("tuesday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("tuesday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() =>
            //   handleCheckHeader("wednesday", !headerCheck.wednesday)
            // }
            >
              <Form.Check
                label="Wednesday"
                // checked={
                //   watch("wednesday[morning]") &&
                //   watch("wednesday[afternoon]") &&
                //   watch("wednesday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("wednesday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() =>
            //   handleCheckHeader("thursday", !headerCheck.thursday)
            // }
            >
              <Form.Check
                label="Thursday"
                // checked={
                //   watch("thursday[morning]") &&
                //   watch("thursday[afternoon]") &&
                //   watch("thursday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("thursday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() => handleCheckHeader("friday", !headerCheck.friday)}
            >
              <Form.Check
                label="Friday"
                // checked={
                //   watch("friday[morning]") &&
                //   watch("friday[afternoon]") &&
                //   watch("friday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("friday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() =>
            //   handleCheckHeader("saturday", !headerCheck.saturday)
            // }
            >
              <Form.Check
                label="Saturday"
                // checked={
                //   watch("saturday[morning]") &&
                //   watch("saturday[afternoon]") &&
                //   watch("saturday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("saturday", event.target.checked)
                // }
              />
            </th>
            <th
            // onClick={() => handleCheckHeader("sunday", !headerCheck.sunday)}
            >
              <Form.Check
                label="Sunday"
                // checked={
                //   watch("sunday[morning]") &&
                //   watch("sunday[afternoon]") &&
                //   watch("sunday[evening]")
                // }
                // onChange={(event) =>
                //   handleCheckHeader("sunday", event.target.checked)
                // }
              />
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          <tr>
            <td>Morning</td>
            <td
            // onClick={() =>
            //   setValue("monday[morning]", !watch("monday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("monday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("tuesday[morning]", !watch("tuesday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("tuesday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("wednesday[morning]", !watch("wednesday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("wednesday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("thursday[morning]", !watch("thursday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("thursday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("friday[morning]", !watch("friday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("friday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("saturday[morning]", !watch("saturday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("saturday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("sunday[morning]", !watch("sunday[morning]"))
            // }
            >
              <Form.Check
              // checked={watch("sunday[morning]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
          </tr>
          <tr>
            <td>Afternoon</td>
            <td
            // onClick={() =>
            //   setValue("monday[afternoon]", !watch("monday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("monday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("tuesday[afternoon]", !watch("tuesday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("tuesday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue(
            //     "wednesday[afternoon]",
            //     !watch("wednesday[afternoon]")
            //   )
            // }
            >
              <Form.Check
              // checked={watch("wednesday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("thursday[afternoon]", !watch("thursday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("thursday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("friday[afternoon]", !watch("friday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("friday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("saturday[afternoon]", !watch("saturday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("saturday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("sunday[afternoon]", !watch("sunday[afternoon]"))
            // }
            >
              <Form.Check
              // checked={watch("sunday[afternoon]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
          </tr>
          <tr>
            <td>Evening</td>
            <td
            // onClick={() =>
            //   setValue("monday[evening]", !watch("monday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("monday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("tuesday[evening]", !watch("tuesday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("tuesday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("wednesday[evening]", !watch("wednesday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("wednesday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("thursday[evening]", !watch("thursday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("thursday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("friday[evening]", !watch("friday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("friday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //   setValue("saturday[evening]", !watch("saturday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("saturday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
            <td
            // onClick={() =>
            //    setValue("sunday[evening]", !watch("sunday[evening]"))
            // }
            >
              <Form.Check
              // checked={watch("sunday[evening]")}
              // onChange={(event) => event.target.checked}
              />
            </td>
          </tr>
        </tbody>
      </Table>
      <Form.Group as={Row} className={"mb-0"}>
        <Form.Group className={"col-lg-12 mb-0"}>
          <Form.Label>Remarks</Form.Label>
          <Form.Control type="text" placeholder="Remarks" name="remarks" />
        </Form.Group>
      </Form.Group>
    </>
  );
}
