import { Box, Typography } from "@mui/material";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import FieldError from "../../../../Components/Forms/FieldError";
import InputDate from "../../../../Components/Input/InputDate";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";
import ReactSelect from "../../../../Components/Select/ReactSelect";
import SectionFormInformation from "../components/SectionFormInformation";
import { genderOptions } from "../options";

const StepSelfForm = () => {
  const {
    register,
    control,
    formState: { errors },
  } = useFormContext();
  return (
    <>
      <SectionFormInformation />

      <Section>
        <SectionTitle title="Self Intake Form" />
        <SectionBody>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Full Name (as in NRIC) Birth Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  {...register("fullName")}
                />
                <FieldError message={errors?.fullName?.message} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Date of Birth</Form.Label>
                <InputDate label="Date of Birth" name="dob" control={control} />
                <FieldError message={errors?.dob?.message} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Gender</Form.Label>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Choose Gender"
                      options={genderOptions}
                      onChange={(item) => onChange(item.value)}
                      value={genderOptions.find((item) => item.value === value)}
                    />
                  )}
                />
                <FieldError message={errors?.gender?.message} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Today's Date</Form.Label>
                <InputDate
                  label="Today`s Date"
                  name="todayDate"
                  control={control}
                />
                <FieldError message={errors?.todayDate?.message} />
              </Form.Group>
            </Col>
          </Row>

          <Box sx={{ marginTop: "14px", marginBottom: "7px" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", fontWeight: "600" }}
            >
              Current Profile
            </Typography>
            <Form.Group className="form-group">
              <Form.Label>
                Who suggested you come to seek counselling?
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("suggestedToSeekCounselling")}
              />
              <FieldError
                message={errors?.suggestedToSeekCounselling?.message}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                In your own words, what are the reasons for seeking counselling
                at this time?
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("reasonsForSeekingCounselling")}
              />
              <FieldError
                message={errors?.reasonsForSeekingCounselling?.message}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                In your own words, what do you hope to gain from being in
                counselling?
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("gainFromBeingInCounselling")}
              />
              <FieldError
                message={errors?.gainFromBeingInCounselling?.message}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>What do you see as your strengths?</Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("yourStrengths")}
              />
              <FieldError message={errors?.yourStrengths?.message} />
            </Form.Group>
          </Box>

          <Box sx={{ marginTop: "20px", marginBottom: "7px" }}>
            <Typography
              variant="h6"
              sx={{ fontSize: "18px", fontWeight: "600" }}
            >
              Background History
            </Typography>
            <Form.Group className="form-group">
              <Form.Label>
                Have you had counselling in the past? (Yes / No)
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("everCounseled")}
              />
              <FieldError message={errors?.everCounseled?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Who did you see? (Indicate the agency/hospital, name and
                designation of helping professional)
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("helpingProfessional")}
              />
              <FieldError message={errors?.helpingProfessional?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Was it a good experience? (Yes / No) Please describe the
                experience.
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("wasItAGoodExperience")}
              />
              <FieldError message={errors?.wasItAGoodExperience?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Please list any medications you are currently taking:
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("listAnyMedications")}
              />
              <FieldError message={errors?.listAnyMedications?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Have you been to the hospital for mental health reasons? (Yes /
                No) Provide the date of last visit if you have.
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("toTheHospitalForMentalHealth")}
              />
              <FieldError
                message={errors?.toTheHospitalForMentalHealth?.message}
              />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label>
                Have you been to the hospital for substance use reasons? (Yes /
                No) Provide the date of last visit if you have.
              </Form.Label>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("toTheHospitalForSubstanceUse")}
              />
              <FieldError
                message={errors?.toTheHospitalForSubstanceUse?.message}
              />
            </Form.Group>
          </Box>
        </SectionBody>
      </Section>
    </>
  );
};

export default StepSelfForm;
