import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DesktopDatePicker from "@mui/lab/DatePicker";
import moment from "moment";
import { Box } from "@mui/material";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Controller } from "react-hook-form";

const InputDate = ({
  label,
  name,
  control,
  disabled = false,
  onChangeHandler = null,
}) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              disabled={disabled}
              label={label}
              value={value}
              onChange={(val) => {
                if (onChangeHandler)
                  onChangeHandler(
                    val !== null ? moment(val).format("DD-MM-YYYY") : val
                  );
                return val !== null
                  ? onChange(moment(val).format("DD-MM-YYYY"))
                  : onChange(val);
              }}
              maxDate={new Date()}
              components={{
                OpenPickerIcon: CalendarTodayIcon,
              }}
              renderInput={({ inputRef, inputProps, InputProps }) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    "& button": {
                      position: "absolute",
                      transform: "translateX(-50px)",
                    },
                    marginRight: "-2px",
                  }}
                >
                  <input
                    {...inputProps}
                    placeholder="DD-MM-YYYY"
                    style={{
                      width: "100%",
                      paddingLeft: "10px",
                      background: disabled ? "#E9ECEF" : "inherit",
                    }}
                    value={value}
                    readOnly
                    ref={inputRef}
                  />
                  {InputProps?.endAdornment}
                </Box>
              )}
            />
          </LocalizationProvider>
        )}
      />
    </>
  );
};

export default InputDate;
