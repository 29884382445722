import { mappingValue } from "./mapping-value";

export const retrieveData = (history, methods) => {
  // redirect to verification form if data doesn't exist
  if (!history.location.state) return history.replace("/verification-form");

  const { enquiry, intake } = history.location.state;

  mappingValue(methods, enquiry, intake);
};
