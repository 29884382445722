export const defaultValues = {
  step: 0,
  // =========== both ( form child & self) =========
  formInfo: "Parent and Child",
  intakeId: "",

  // ---- step 1 -----
  fullName: "",
  dob: "",
  gender: "",
  todayDate: "",

  // ---- step 2 ----
  mother: "",
  father: "",
  maritalStatus: "",
  householdMembers: [],
  childsSignificantOthers: [],

  // ---- step 3 ----
  childEverExperiencedAbuse: "",
  childEverMadeStatementsOfWantingToHurt: "",
  childEverPurposelyHurtHimself: "",
  childEverExperiencedAnySeriousEmitionalLosses: "",
  thingsThatAreCurrentlyStressful: "",

  // =========== form child ============
  behaviouralExcesses: "",
  behaviouralDeficits: "",
  behaviouralAssets: "",
  otherConcerns: "",
  treatmentGoals: "",

  describePastCounselling: "",

  schoolName: "",
  formTeacherName: "",
  currentClass: "",
  childsTeacherSayAbout: "",
  childEverRepeatedAClass: "",
  childEverReceivedEducationServices: "",
  childExperiencedProblems: [],

  // ========== form self ==========
  suggestedToSeekCounselling: "",
  reasonsForSeekingCounselling: "",
  gainFromBeingInCounselling: "",
  yourStrengths: "",
  everCounseled: "",
  helpingProfessional: "",
  wasItAGoodExperience: "",
  listAnyMedications: "",
  toTheHospitalForMentalHealth: "",
  toTheHospitalForSubstanceUse: "",
};
