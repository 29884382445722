import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  CircularProgress,
  Container,
  Stack,
  Typography,
} from "@mui/material";
import { Suspense, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import BannerIntake from "../../../Components/BannerIntake";
import { Footer } from "../../../Components/Footer";
import { Header } from "../../../Components/Header";
import LazyLoad from "../../../Components/LazyLoading";
import ModalError from "../../../Components/Modal/ModalError";
import ModalValidation from "../../../Components/Modal/ModalValidation";
import { defaultValues } from "./default-values";
import FormChild from "./FormChild";
import FormSelf from "./FormSelf";
import { fetchLatestData } from "./helpers/fetch-latest-data";
import { retrieveData } from "./helpers/retrieve-data";
import { validationSchema } from "./helpers/validation-schema";
import "./style.css";

const FormIntakeImpactful = () => {
  const history = useHistory();
  const [loadingData, setLoadingData] = useState(true);
  const [modalError, setModalError] = useState({
    show: false,
    error: null,
    message: null,
  });
  const [modalValidation, setModalValidation] = useState({
    show: false,
    errors: {},
  });

  // --------- validation schema -------------
  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  });

  useEffect(() => {
    retrieveData(history, methods);
    try {
      (async () => {
        await fetchLatestData(history, methods);
        setLoadingData(false);
      })();
    } catch (error) {
      console.log(error);
      setLoadingData(false);
    }
  }, []);

  const openModalValidation = () => {
    if (Object.entries(methods.formState.errors).length > 0) {
      setModalValidation({
        ...modalValidation,
        show: true,
        errors: methods.formState.errors,
      });
    }
  };

  return (
    <>
      <Suspense fallback={<LazyLoad />}>
        <Header />
        <BannerIntake />
        {loadingData ? (
          <Box
            sx={{
              display: loadingData ? "block" : "none",
              marginBottom: "120px",
            }}
          >
            <Stack
              direction={"column"}
              justifyContent="center"
              alignItems={"center"}
              gap={3}
            >
              <CircularProgress />
              <Typography color={"primary"} fontWeight={600}>
                Please wait...
              </Typography>
            </Stack>
          </Box>
        ) : (
          <Container
            maxWidth="xl"
            className="complete-form impact-care"
            sx={{ display: !loadingData ? "block" : "none" }}
          >
            <FormProvider {...methods}>
              {methods.watch("formInfo") === "Parent and Child" ? (
                <FormChild
                  setModalError={setModalError}
                  openModalValidation={openModalValidation}
                />
              ) : (
                <FormSelf
                  setModalError={setModalError}
                  openModalValidation={openModalValidation}
                />
              )}
            </FormProvider>
          </Container>
        )}

        <Footer />

        <ModalError
          show={modalError.show}
          error={modalError.error}
          close={() =>
            setModalError({ show: false, error: null, message: null })
          }
          message={modalError.message}
        />
        <ModalValidation
          show={modalValidation.show}
          onClose={() =>
            setModalValidation({ ...modalValidation, show: false })
          }
          errors={modalValidation.errors}
        />
      </Suspense>
    </>
  );
};

export default FormIntakeImpactful;
