import React from "react";
import "./index.css";

export function TitleSignupDetail({ title }) {
  return (
    <>
      <span className="title-signup-detail">{title}</span>
      <div className="border-signup-detail" />
    </>
  );
}
