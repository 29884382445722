import React, { useState, lazy, Suspense, useEffect } from "react";
import { Header } from "../../Components/Header/index";
import { Footer } from "../../Components/Footer/index";
import BannerEnquiry from "../../Components/BannerEnquiry";
import LazyLoad from "../../Components/LazyLoading";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const FormEnquiry = lazy(() =>
  import("../../Components/Forms/FormEnquiry/index")
);

export function EnquiryForm() {
  const [pillarActive, setPillarActive] = useState("IMPACTFUL");
  const [isReferrer, setIsReferrer] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let { isReferrer, pillar } = queryString.parse(window.location.search);
    setIsReferrer(isReferrer === "true");
    if (pillar) {
      pillar = pillar?.toUpperCase();
      setPillarActive(pillar);
    }
  }, []);

  useEffect(() => {
    let targetUrl = `${
      process.env.PUBLIC_URL
    }/enquiry-form?pillar=${pillarActive.toLowerCase()}${
      !!isReferrer ? "&isReferrer=true" : ""
    }`;
    history.push(targetUrl);
  }, [pillarActive, isReferrer]);

  return (
    <>
      <Helmet>
        <title>Enquiry Form - Campus Impact</title>
      </Helmet>
      <Suspense fallback={<LazyLoad />}>
        <Header />
        <BannerEnquiry pillar={pillarActive} />
        <FormEnquiry setPillarActive={setPillarActive} />
        <Footer />
      </Suspense>
    </>
  );
}
