import React from "react";
import { Header } from "../../Components/Header/index";
import { ImageTitle } from "../../Components/ImageTitle/index";
import { FormComplete } from "../../Components/Forms/FormComplete/index";
import { Footer } from "../../Components/Footer/index";
import { Helmet } from "react-helmet";

export function CompleteForm() {
  return (
    <>
      <Helmet>
        <title>Complete Form - Campus Impact</title>
      </Helmet>
      <FormComplete />
    </>
  );
}
