import React from "react";
import { Form } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import FieldError from "../../../../Components/Forms/FieldError";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";

const StepEducationHistory = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <Section>
        <SectionTitle title="Other History Form" />
        <SectionBody>
          <Form.Group className="form-group">
            <Form.Label>
              Have you ever experienced any type of abuse (physical, sexual, or
              verbal)? If so, please describe:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverExperiencedAbuse")}
            />
            <FieldError message={errors?.childEverExperiencedAbuse?.message} />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Have you ever made statements of wanting to hurt yourself or
              seriously hurt someone else? If yes, please describe the
              situation:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverMadeStatementsOfWantingToHurt")}
            />
            <FieldError
              message={errors?.childEverMadeStatementsOfWantingToHurt?.message}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Have you ever purposely hurt yourself or another? If yes, please
              describe the situation:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverPurposelyHurtHimself")}
            />
            <FieldError
              message={errors?.childEverPurposelyHurtHimself?.message}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Have you ever experienced any serious emotional losses (such as a
              death of or physical separation from a parent, other caregivers,
              or significant others)? If yes, please explain:
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("childEverExperiencedAnySeriousEmitionalLosses")}
            />
            <FieldError
              message={
                errors?.childEverExperiencedAnySeriousEmitionalLosses?.message
              }
            />
          </Form.Group>
          <Form.Group className="form-group">
            <Form.Label>
              Finally, what are some of the things that are currently stressful
              to you and your family?
            </Form.Label>
            <Form.Control
              as="textarea"
              placeholder="Type here..."
              {...register("thingsThatAreCurrentlyStressful")}
            />
            <FieldError
              message={errors?.thingsThatAreCurrentlyStressful?.message}
            />
          </Form.Group>
        </SectionBody>
      </Section>
    </>
  );
};

export default StepEducationHistory;
