import React from 'react'
import { Modal , Button } from 'react-bootstrap'

const ModalError = ({ show , close , error , message }) => {
  return (
    <Modal
        size="lg"
        show={show}
        onHide={() => close()}
        centered
    >
        <Modal.Header closeButton={true}>
            <Modal.Title>{error?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <h4>{message}</h4>
            <p>{error?.stack}</p>
        </Modal.Body>
    </Modal>
  )
}

export default ModalError