import * as Yup from "yup";
import { LSSchema } from "./general-validation";
import { LCSchema } from "./learning-curve-validation";
import { SBSchema } from "./study-buddy-validation";

/* 
  ===== Validation Logic ======
  1. LC Field
  - validated when service types only LC and isDraft false
  - validated when service types includes both, ignore isDraft
  2. SB Field
  - validated always on the last step
  3. LC & SB Field (LS Field)
  - if service types only lc, should validated when submit
  - if service types includes sb, validated on the last step, ignore isDraft
*/

export const validationSchema = Yup.object().shape({
  ...LSSchema,
  ...LCSchema,
  ...SBSchema,
});
