import * as Yup from "yup";
import {
  fatherFields,
  FatherValidation,
  guardianFields,
  GuardianValidation,
  motherFields,
  MotherValidation,
  sbFields,
  SBFieldValidation,
  validateContact,
  validateDob,
  validateNRIC,
} from "./validationHelper";
import { fnUniqueNRIC } from "./fnUniqueNRIC";

Yup.addMethod(Yup.string, "postalCode", function (err) {
  return this.test("postalCode", err, (str) =>
    str?.length === 6 ? !isNaN(str) : false
  );
});

export const SBSchema = {
  session: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  holidayTiming: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(["session"], {
      is: (session) => /holiday$/i.test(session),
      then: Yup.string().required("This field is required"),
    }),
  }),
  time: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(["holidayTiming"], {
      is: (holidayTiming) => /half[ -]?day$/i.test(holidayTiming),
      then: Yup.string().required("This field is required"),
    }),
  }),
  sbFullName: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbNric: Yup.string()
    .when(["step"], {
      is: (step) => step === 0,
      then: Yup.string()
        .test("sbNric", null, (str, ctx) => validateNRIC(str, ctx, "sbNric"))
        .test("sbNric", "NRIC must be unique", fnUniqueNRIC),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().required("Please provide ID number"),
    }),
  sbGender: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbSchoolName: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbDob: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string()
      .required("This field is required")
      .test("dob", "Sorry, your date of birth can't be in the future", (val) =>
        validateDob("dob", val)
      ),
  }),
  sbEducationLevel: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().nullable().required("This field is required"),
  }),
  sbRace: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbOtherRace: Yup.string().when(["race"], {
    is: (val) => /others/i.test(val),
    then: Yup.string().required("This field is required"),
  }),
  citizenship: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  otherCitizenship: Yup.string().when(["citizenship"], {
    is: (val) => /others/i.test(val),
    then: Yup.string().required("This field is required"),
  }),
  sbPostalCode: Yup.string()
    .postalCode()
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().postalCode().required("This field is required"),
    }),
  sbUnitNumber: Yup.string().matches(/^[\d-]{1,6}$/, "Unit number is invalid"),
  sbAddress: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbContact: Yup.string()
    .when(["step"], {
      is: (step) => step === 0,
      then: Yup.string().test(
        "sbContact",
        "Please provide a valid contact number",
        (value) =>
          !value.length
            ? true
            : /^na$/i.test(value)
            ? true
            : validateContact(value)
      ),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string()
        .required("This field is required")
        .test("sbContact", "Please provide a valid contact number", (value) =>
          /^na$/i.test(value) ? true : validateContact(value)
        ),
    }),
  punctuality: Yup.string(),
  dayLate: Yup.array(),
  reasonComing: Yup.string(),
  dayNotAttending: Yup.array(),
  reasonNotAttending: Yup.string(),

  fatherName: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(fatherFields, {
      is: FatherValidation,
      then: Yup.string().required("This field is required"),
    }),
  }),
  fatherNric: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string()
        .test("fatherNric", null, (str, ctx) =>
          validateNRIC(str, ctx, "fatherNric")
        )
        .test("nric", "NRIC must be unique", fnUniqueNRIC),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(fatherFields, {
        is: FatherValidation,
        then: Yup.string().required("This field is required"),
      }),
    }),
  fatherContactNo: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string().test(
        "motherContactNo",
        "Please provide a valid contact number",
        validateContact
      ),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(fatherFields, {
        is: FatherValidation,
        then: Yup.string()
          .required("This field is required")
          .test(
            "fatherContactNo",
            "Please provide a valid contact number",
            validateContact
          ),
      }),
    }),
  fatherEmail: Yup.string()
    .email("Please provide a valid email")
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(fatherFields, {
        is: FatherValidation,
        then: Yup.string()
          .email("Please provide a valid email")
          .required("This field is required"),
      }),
    }),
  fatherOccupation: Yup.string(),
  motherName: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(motherFields, {
      is: MotherValidation,
      then: Yup.string().required("This field is required"),
    }),
  }),
  motherNric: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string()
        .test("motherNric", null, (str, ctx) =>
          validateNRIC(str, ctx, "motherNric")
        )
        .test("nric", "NRIC must be unique", fnUniqueNRIC),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(motherFields, {
        is: MotherValidation,
        then: Yup.string().required("This field is required"),
      }),
    }),
  motherContactNo: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string().test(
        "motherContactNo",
        "Please provide a valid contact number",
        validateContact
      ),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(motherFields, {
        is: MotherValidation,
        then: Yup.string()
          .required("This field is required")
          .test(
            "motherContactNo",
            "Please provide a valid contact number",
            validateContact
          ),
      }),
    }),
  motherEmail: Yup.string()
    .email("Please provide a valid email")
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(motherFields, {
        is: MotherValidation,
        then: Yup.string()
          .email("Please provide a valid email")
          .required("This field is required"),
      }),
    }),
  motherOccupation: Yup.string(),

  maritalStatuses: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  mainCaregiver: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),

  guardianName: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(guardianFields, {
      is: GuardianValidation,
      then: Yup.string().required("This field is required"),
    }),
  }),
  guardianContactNo: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string().test(
        "guardianContactNo",
        "Please provide a valid contact number",
        validateContact
      ),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(guardianFields, {
        is: GuardianValidation,
        then: Yup.string()
          .required("This field is required")
          .test(
            "guardianContactNo",
            "Please provide a valid contact number",
            validateContact
          ),
      }),
    }),
  guardianNric: Yup.string()
    .when(["step"], {
      is: (step) => step === 1,
      then: Yup.string()
        .test("guardianNric", null, (str, ctx) =>
          validateNRIC(str, ctx, "guardianNric")
        )
        .test("nric", "NRIC must be unique", fnUniqueNRIC),
    })
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(guardianFields, {
        is: GuardianValidation,
        then: Yup.string().required("This field is required"),
      }),
    }),
  guardianEmail: Yup.string()
    .email("Please provide a valid email")
    .when(sbFields, {
      is: SBFieldValidation,
      then: Yup.string().when(guardianFields, {
        is: GuardianValidation,
        then: Yup.string()
          .email("Please provide a valid email")
          .required("This field is required"),
      }),
    }),

  whoIsStaying: Yup.array().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.array().required("This field is required"),
  }),
  allergies: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sourceOfReferral: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  provideSourceOfReferral: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().when(["sourceOfReferral"], {
      is: (val) => !/walk in/i.test(val),
      then: Yup.string().required("This field is required"),
    }),
  }),
  payslipSB: Yup.mixed().test(
    "payslipSB",
    "File Size is too large",
    (value) => {
      if (value) {
        return value.size <= 10000000;
      }
      return true;
    }
  ),
  sbNoFamilyMembers: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbHouseholdIncome: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
  sbAccommodationType: Yup.string().when(sbFields, {
    is: SBFieldValidation,
    then: Yup.string().required("This field is required"),
  }),
};
