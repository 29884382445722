import { Stack, Typography } from "@mui/material";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { Controller, useFormContext } from "react-hook-form";
import InputDate from "../../../../Components/Input/InputDate";
import Section from "../../../../Components/Section/Section";
import SectionBody from "../../../../Components/Section/SectionBody";
import SectionTitle from "../../../../Components/Section/SectionTitle";
import ReactSelect from "../../../../Components/Select/ReactSelect";
import SectionFormInformation from "../components/SectionFormInformation";
import { genderOptions } from "../options";
import FieldError from "../../../../Components/Forms/FieldError";

const styles = {
  label: {
    fontWeight: "500",
    fontSize: "16px",
    marginBottom: "8px",
  },
};

const StepChildForm = () => {
  const {
    control,
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <SectionFormInformation />

      <Section>
        <SectionTitle title="Parent & Child Referral" />
        <SectionBody>
          <Typography variant="h6" sx={{ fontSize: "18px", fontWeight: "600" }}>
            Please provide the following information about your child:
          </Typography>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Full Name (as in NRIC) Birth Date</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Full Name"
                  {...register("fullName")}
                />
                <FieldError message={errors?.fullName?.message} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Date of Birth</Form.Label>
                <InputDate label="Date of Birth" name="dob" control={control} />
                <FieldError message={errors?.dob?.message} />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Gender</Form.Label>
                <Controller
                  control={control}
                  name="gender"
                  render={({ field: { onChange, value } }) => (
                    <ReactSelect
                      placeholder="Choose Gender"
                      options={genderOptions}
                      onChange={(item) => onChange(item.value)}
                      value={genderOptions.find((item) => item.value === value)}
                    />
                  )}
                />
                <FieldError message={errors?.gender?.message} />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="form-group">
                <Form.Label>Today's Date</Form.Label>
                <InputDate
                  label="Today's Date"
                  name="todayDate"
                  control={control}
                />
                <FieldError message={errors?.todayDate?.message} />
              </Form.Group>
            </Col>
          </Row>
          <Stack direction="column" sx={{ marginTop: "40px" }} spacing={2}>
            <Form.Group className="form-group">
              <Form.Label style={{ fontSize: "18px" }}>
                Behavioural Excesses:
              </Form.Label>
              <Typography variant="body1" sx={styles.label}>
                What does your child currently do too often, too much, or at the
                wrong times that gets him/her in trouble? Please list all the
                behaviours you can think of.
              </Typography>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("behaviouralExcesses")}
              />
              <FieldError message={errors?.behaviouralExcesses?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label style={{ fontSize: "18px" }}>
                Behavioural Deficits:
              </Form.Label>
              <Typography variant="body1" sx={styles.label}>
                What does your child fail to do as often as you would like, as
                much as you would like, or when you would like? Please list all
                the behaviours you can think of.
              </Typography>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("behaviouralDeficits")}
              />
              <FieldError message={errors?.behaviouralDeficits?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label style={{ fontSize: "18px" }}>
                Behavioural Assets:
              </Form.Label>
              <Typography variant="body1" sx={styles.label}>
                What does your child do that you like? What does he/she do that
                other people like?
              </Typography>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("behaviouralAssets")}
              />
              <FieldError message={errors?.behaviouralAssets?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label style={{ fontSize: "18px" }}>
                Others Concerns:
              </Form.Label>
              <Typography variant="body1" sx={styles.label}>
                Do you have any other concerns about your child or your family
                that you have not mentioned yet?
              </Typography>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("otherConcerns")}
              />
              <FieldError message={errors?.otherConcerns?.message} />
            </Form.Group>
            <Form.Group className="form-group">
              <Form.Label style={{ fontSize: "18px" }}>
                Treatment Goals:
              </Form.Label>
              <Typography variant="body1" sx={styles.label}>
                From your preceding list of your child's behaviours and your
                family concerns, what problem behaviours do you want to see
                change FIRST: and how much must they change for you to be
                satisfied?
              </Typography>
              <Form.Control
                as="textarea"
                placeholder="Type here..."
                {...register("treatmentGoals")}
              />
              <FieldError message={errors?.treatmentGoals?.message} />
            </Form.Group>
          </Stack>
        </SectionBody>
      </Section>
    </>
  );
};

export default StepChildForm;
