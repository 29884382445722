import React from "react";
import { Route, Switch } from "react-router-dom";
import IntakeImpactful from "./IntakeImpactful";
import { Helmet } from "react-helmet";

export function IntakeForm() {
  return (
    <>
      <Helmet>
        <title>Intake Form - Campus Impact</title>
      </Helmet>
      <Switch>
        <Route path="/intake-form/impactful" component={IntakeImpactful} />
      </Switch>
    </>
  );
}
