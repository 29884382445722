import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { SignupModalVerificationCode } from "../Modal/SignupModalVerificationCode";
import "./SummaryFormStep.css";

export function SummaryFormStep() {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Row>
        <h6>
          You have reached the last step of the sign up process and agreed to
          the Terms and Conditions listed in the documents below:
        </h6>
      </Row>
      <Row className="mt-3">
        <ol type={"1"} className="list-stepform">
          <li>Application Form</li>
          <li>Volunteer Preference</li>
          <li>Consent Form</li>
        </ol>
      </Row>
      <h6 className="mt-3">
        Please click “Send Verification Code” below to receive the code for
        final agreement.
      </h6>
      <Row>
        <Col xl={12} lg={12} xs={12}>
          <button
            type="button"
            className="btn btn-primary btn-sm btn-verification"
            style={{ width: "10rem", marginTop: "4rem" }}
            onClick={() => setShowModal(true)}
          >
            Send Verification Code
          </button>
        </Col>
      </Row>
      <SignupModalVerificationCode
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}
