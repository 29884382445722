export const getGeneralPayloads = (data) => {
  let payloads = {};
  if (data.step === 1) {
    payloads = {
      isSubmit: false,
      fullName: data.fullName,
      // dob: data.dob,
      gender: data.gender,
      todayDate: data.todayDate,
    };
  }
  if (data.step === 2) {
    payloads = {
      isSubmit: false,
      mother: data.mother,
      father: data.father,
      maritalStatus: data.maritalStatus,
      householdMembers: data.householdMembers,
      childsSignificantOthers: data.childsSignificantOthers,
    };
  }
  if (data.step === 3) {
    payloads = {
      isSubmit: true,
      childEverExperiencedAbuse: data.childEverExperiencedAbuse,
      childEverMadeStatementsOfWantingToHurt:
        data.childEverMadeStatementsOfWantingToHurt,
      childEverPurposelyHurtHimself: data.childEverPurposelyHurtHimself,
      childEverExperiencedAnySeriousEmitionalLosses:
        data.childEverExperiencedAnySeriousEmitionalLosses,
      thingsThatAreCurrentlyStressful: data.thingsThatAreCurrentlyStressful,
    };
  }
  return {
    intakeId: data.intakeId,
    enquiryId: data.enquiryId,
    formInfo: data.formInfo,
    ...payloads,
  };
};

export const getChildPayloads = (data) => {
  if (data.step === 1) {
    return {
      behaviouralExcesses: data.behaviouralExcesses,
      behaviouralDeficits: data.behaviouralDeficits,
      behaviouralAssets: data.behaviouralAssets,
      otherConcerns: data.otherConcerns,
      treatmentGoals: data.treatmentGoals,
    };
  }
  if (data.step === 2) {
    return {
      describePastCounselling: data.describePastCounselling,
    };
  }
  if (data.step === 3) {
    return {
      schoolName: data.schoolName,
      formTeacherName: data.formTeacherName,
      currentClass: data.currentClass,
      childsTeacherSayAbout: data.childsTeacherSayAbout,
      childEverRepeatedAClass: data.childEverRepeatedAClass,
      childEverReceivedEducationServices:
        data.childEverReceivedEducationServices,
      childExperiencedProblems: data.childExperiencedProblems,
    };
  }
  return {};
};

export const getSelfPayloads = (data) => {
  if (data.step === 1) {
    return {
      suggestedToSeekCounselling: data.suggestedToSeekCounselling,
      reasonsForSeekingCounselling: data.reasonsForSeekingCounselling,
      gainFromBeingInCounselling: data.gainFromBeingInCounselling,
      yourStrengths: data.yourStrengths,
      everCounseled: data.everCounseled,
      helpingProfessional: data.helpingProfessional,
      wasItAGoodExperience: data.wasItAGoodExperience,
      listAnyMedications: data.listAnyMedications,
      toTheHospitalForMentalHealth: data.toTheHospitalForMentalHealth,
      toTheHospitalForSubstanceUse: data.toTheHospitalForSubstanceUse,
    };
  }
  return {};
};
