import React from "react";
import { Suspense } from "react";
import FormIntakeImpactful from "../../Components/Forms/FormIntake/FormIntakeImpactful";
import LazyLoad from "../../Components/LazyLoading";

const IntakeImpactcare = () => {
  return (
    <>
      <Suspense fallback={<LazyLoad />}>
        <FormIntakeImpactful />
      </Suspense>
    </>
  );
};

export default IntakeImpactcare;
