import React, { useState, useEffect } from "react";
import { Card, Form, Button, Row, Col } from "react-bootstrap";
import {
  Step,
  StepLabel,
  Stepper,
  StepConnector,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { LazyLoadImage } from "react-lazy-load-image-component";
import PropTypes from "prop-types";

import { ApplicationFormStep } from "../../Stepper/ApplicationFormStep";
import { VolunteerPreferenceFormStep } from "../../Stepper/VolunteerPreferenceFormStep";
import { ConsentForm } from "../../Stepper/ConsentForm";
import { SummaryFormStep } from "../../Stepper/SummaryFormStep";

import Check from "@material-ui/icons/Check";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import clsx from "clsx";
import "./index.css";

export function FormComplete() {
  const [activeStep, setActiveStep] = useState(0);
  const [stepLabel] = useState([
    "Application Form",
    "Volunteer Preference",
    "Consent Form",
    "Summary",
  ]);

  const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: window.screen > 420 ? "calc(-40% + 6px)" : "calc(-36% + 6px)",
      right: window.screen > 420 ? "calc(60% + 5px)" : "calc(70% + 5px)",
    },
    active: {
      "& $line": {
        borderColor: "#eaeaf0",
      },
    },
    completed: {
      "& $line": {
        borderColor: "#eaeaf0",
      },
    },
    line: {
      borderColor: "#eaeaf0",
      borderTopWidth: 2,
      borderRadius: 1,
    },
  })(StepConnector);

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: "#0275d8",
      display: "flex",
      height: 22,
      alignItems: "center",
    },

    active: {
      background: "#0275d8",
      color: "#0275d8",
      borderRadius: "100%",
    },
    circle: {
      width: 20,
      height: 20,
      backgroundColor: "#0275d8",
      borderRadius: "100%",
    },
    completed: {
      color: "white",
      zIndex: 1,
      fontSize: 20,
      borderRadius: "50%",
      background: "green",
    },
  });

  QontoStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
  };

  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? (
          <Check className={classes.completed} />
        ) : (
          <div className={classes.circle} />
        )}
      </div>
    );
  }

  const handleNext = () => {
    if (activeStep <= 3) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
      window.scrollTo(0, 0);
    }
  };

  function getStepContent(step) {
    return (
      <>
        <div style={{ display: step === 0 ? "block" : "none" }}>
          <ApplicationFormStep />
        </div>
        <div style={{ display: step === 1 ? "block" : "none" }}>
          <VolunteerPreferenceFormStep />
        </div>
        <div style={{ display: step === 2 ? "block" : "none" }}>
          <ConsentForm />
        </div>
        <div
          style={{
            display: step === 3 ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            margin: "50px 0px 0px 0px",
            padding: "0",
          }}
        >
          <SummaryFormStep />
        </div>
      </>
    );
  }

  return (
    <div className="container-card-signup-detail">
      <Card className="card-signup-detail">
        <Row>
          <Col xl={{ span: 2, order: 1 }} xs={{ span: 6, order: 1 }}>
            {" "}
            <div className="d-flex">
              <LazyLoadImage
                alt="Logo"
                src={
                  "https://www.giving.sg/image/organization_logo?img_id=3112940&1543457385000"
                }
                className="logo-signup-page"
              />
            </div>
          </Col>
          <Col xl={{ span: 8, order: 2 }} xs={{ span: 12, order: 3 }}>
            {" "}
            <Stepper
              className="stepper"
              orientation="horizontal"
              alternativeLabel
              activeStep={activeStep}
              connector={<QontoConnector />}
            >
              {stepLabel.map((label) => {
                return (
                  <Step key={label}>
                    <StepLabel>
                      <span className="step-label">{label}</span>
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Col>
          <Col xl={{ span: 2, order: 3 }} xs={{ span: 6, order: 2 }}>
            <div className="d-flex justify-content-end">
              <LazyLoadImage
                alt="Logo"
                src={
                  "https://www.giving.sg/image/organization_logo?img_id=3112940&1543457385000"
                }
                className="logo-signup-page"
              />
            </div>
          </Col>
        </Row>
        <Card.Body style={{ padding: "0" }}>
          {getStepContent(activeStep)}
        </Card.Body>
        <div className="m-auto d-flex mb-4 mt-5">
          <Row>
            {activeStep > 0 && (
              <Col xl={6} lg={6} xs={12} className="mb-2">
                <button
                  type="button"
                  onClick={handleBack}
                  className="btn-back btn btn-sm "
                  style={{ background: "#EDEDED" }}
                >
                  <FontAwesomeIcon
                    icon={faArrowLeft}
                    style={{ marginRight: "10px" }}
                  />
                  Back
                </button>
              </Col>
            )}
            {activeStep <= 2 ? (
              <Col xl={activeStep !== 0 ? 6 : 12} lg={6} xs={12}>
                <Button
                  className="btn-next"
                  style={{
                    backgroundColor: "#0275d8",
                  }}
                  onClick={handleNext}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{ marginRight: "25px" }}
                  />
                  <span>Next</span>
                </Button>
              </Col>
            ) : null}
          </Row>
        </div>
      </Card>
    </div>
  );
}
