import { getEnquiryDataImpactCare } from "../../../../datasources/enquiryRepo";
import { mappingValue } from "./mapping-value";

export const fetchLatestData = async (history, methods) => {
  if (!history.location.state) return history.replace("/verification-form");

  const state = history.location.state;
  const { data } = await getEnquiryDataImpactCare({ id: state.enquiry.Id });
  const { enquiry, intake, householdMembers, significantOthers } = data;
  mappingValue(methods, enquiry, intake, householdMembers, significantOthers);
};
