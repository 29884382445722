import React from "react";
import { Col, Navbar, Row } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import HotlineIcon from "../../assets/Hotline.svg";
import mailIcon from "../../assets/li_mail.svg";
import mapIcon from "../../assets/li_map-pin.svg";
import phoneIcon from "../../assets/li_phone.svg";
import "./index.css";

export function Header() {
  return (
    <div className="navbar-container">
      <div className="container-nav-info align-items-center">
        <div className="nav-content-info d-flex justify-content-around align-items-center">
          <img src={mapIcon} alt="Icon Map" />
          <a
            href="mailto:enquiries@campusimpact.org.sg"
            className="content-info"
          >
            <span>enquiries@campusimpact.org.sg</span>
          </a>
        </div>
        <div className="nav-content-info d-flex justify-content-between align-items-center">
          <img src={mailIcon} alt="Icon Mail" />
          <span className="content-info">
            151 Yishun Street 11 #01-26, 760151
          </span>
        </div>
        <div className="nav-content-info d-flex justify-content-between align-items-center">
          <img src={phoneIcon} alt="Icon Phone" />
          <a href="tel:+65 6482 1324" className="content-info">
            <span>+65 6482 1324</span>
          </a>
        </div>
      </div>

      <Navbar
        className="navbar-bottom"
        style={{ backgroundColor: "#ffeee1" }}
        expand="lg"
      >
        <Navbar.Brand href="#home">
          <a href="https://www.campusimpact.org.sg/">
            <LazyLoadImage
              alt="Logo"
              src={
                "https://www.giving.sg/image/organization_logo?img_id=3112940&1543457385000"
              }
              className="logo-nav"
            />
          </a>{" "}
        </Navbar.Brand>
        <div
          className="container-icon-hotline d-flex justify-content-end align-items-center mb-4"
          style={{ width: "100%" }}
        >
          <img src={HotlineIcon} alt="Hotline Icon" />

          <div className="container-navbar-right">
            <div className="mt-3 container-hotline">
              <p className="m-0 info-hotline">Counseling</p>
              <p className="m-0 info-hotline">Hotline</p>
              <Row>
                <Col>
                  <span className="content-info">+6555 7667</span>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Navbar>
    </div>
  );
}
