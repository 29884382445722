import React from "react";
import { Step, Stepper, StepLabel, Box } from "@mui/material";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { orange } from "@material-ui/core/colors";
import { styled } from "@mui/material/styles";

const Connector = styled(StepConnector)(({ theme }) => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "#F2F5ED",
    borderWidth: "5px",
    borderRadius: "4px",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fff2e9",
      borderWidth: "5px",
      borderRadius: "4px",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#fff2e9",
      borderWidth: "5px",
      borderRadius: "4px",
    },
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: orange[500],
    fontSize: "50px",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "rgb(253,147,68)",
    zIndex: 1,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      <Box
        sx={{
          backgroundColor: active || completed ? "#fd9344" : "#F2F5ED",
          width: "24px",
          height: "24px",
          margin: "8px 0px",
          borderRadius: "50%",
        }}
      ></Box>
    </QontoStepIconRoot>
  );
}

const StepperComponent = ({
  activeStep,
  listSteps,
  firstLabelTransform,
  lastLabelTransform,
}) => {
  return (
    <Stepper
      activeStep={activeStep}
      alternativeLabel
      connector={<Connector />}
      sx={{
        display: { xs: "none", md: "flex" },
        "&.MuiStepper-root": {
          width: "148%",
          transform: "translateX(-16.2%)",
          position: "relative",
        },
      }}
    >
      {listSteps.map((label, i) => {
        const stepProps = {};
        const labelProps = {};
        return (
          <Step key={label} {...stepProps}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              {...labelProps}
              sx={{
                "& .MuiStepLabel-label": {
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  margin: "8px auto",
                  lineHeight: "24px",
                  fontWeight: "400",
                  color: "#AFB1AA",
                  transform:
                    i === 0
                      ? firstLabelTransform || "translateX(70px)"
                      : i === listSteps.length - 1
                      ? lastLabelTransform || "translateX(-100px)"
                      : "translateX(-0px)",
                  "&.Mui-active , &.Mui-completed": {
                    color: "#FD9344",
                    fontWeight: "600",
                  },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        );
      })}
    </Stepper>
  );
};

export default StepperComponent;
