import React from "react";
import { Typography } from "@mui/material";

const SectionTitle = ({ title }) => {
  return (
    <Typography variant="h4" className="title-section" fontWeight={600}>
      {title}
    </Typography>
  );
};

export default SectionTitle;
